import globalTheme from '@bluheadless/ui/src/theme/default'

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: 'var(--button-font-family)',
				'--font-weight-axis': 'var(--button-font-weight)',
				'--font-variation-settings': `'wght' var(--font-weight-axis)`,
				fontWeight: 'var(--button-font-weight)',
				fontSize: 'var(--button-primary-size)',
				lineHeight: 'var(--button-primary-size)',
				padding: 'calc(var(--spacing-1) * 2.5) var(--spacing-3)',
				// minWidth: 159,
				textTransform: 'uppercase',
				boxShadow: 'none',
				borderRadius: 'var(--button-border-radius)',
				border: '2px solid transparent',
				'& .MuiButton-startIcon': {
					marginLeft: 0,
					marginRight: 'var(--spacing-2)',
					'&>*:nth-of-type(1)': {
						fontSize: globalTheme.typography.pxToRem(16),
					},
				},
				'& .MuiButton-endIcon': {
					marginLeft: 'var(--spacing-2)',
					marginRight: 0,
				},
			},
			contained: {
				letterSpacing: 'var(--button-letter-spacing)',
			},
			containedPrimary: {
				backgroundColor: 'var(--button-primary-bgcolor)',
				backdropFilter: 'var(--backdrop-filter)',
				color: 'var(--button-primary-color)',
				borderColor: 'var(--button-primary-bgcolor)',
				minHeight: 'unset',
				'&:hover, :focus': {
					color: 'var(--button-primary-hover-color)',
					backgroundColor: 'var(--button-primary-hover-bgcolor)',
					borderColor: 'var(--button-primary-hover-color)',
				},
			},
			containedSecondary: {
				backgroundColor: 'var(--button-secondary-bgcolor)',
				backdropFilter: 'var(--backdrop-filter)',
				color: 'var(--button-secondary-color)',
				minHeight: 'unset',
				borderColor: 'var(--button-secondary-color)',
				'&:hover, :focus': {
					color: 'var(--button-secondary-hover-color)',
					backgroundColor: 'var(--button-secondary-hover-bgcolor)',
				},
			},
			ghost: {
				letterSpacing: 'var(--button-letter-spacing)',
				backgroundColor: 'var(--button-ghost-bgcolor)',
				backdropFilter: 'var(--backdrop-filter)',
				color: 'var(--button-ghost-color)',
				minHeight: 'unset',
				textTransform: 'uppercase',
				borderColor: 'var(--button-ghost-color)',
				'&:hover, :focus': {
					color: 'var(--button-ghost-hover-color)',
					backgroundColor: 'var(--button-ghost-hover-bgcolor)',
				},
			},
			ghostPrimary: {
				backgroundColor: 'var(--button-ghost-bgcolor)',
				backdropFilter: 'var(--backdrop-filter)',
				color: 'var(--button-ghost-color)',
				minHeight: 'unset',
				textTransform: 'uppercase',
				borderColor: 'var(--button-ghost-color)',
				'&:hover, :focus': {
					color: 'var(--button-ghost-hover-color)',
					backgroundColor: 'var(--button-ghost-hover-bgcolor)',
				},
			},
			ghostSecondary: {
				backgroundColor: 'var(--button-ghost-secondary-bgcolor)',
				backdropFilter: 'var(--backdrop-filter)',
				color: 'var(--button-ghost-secondary-color)',
				minHeight: 'unset',
				textTransform: 'none',
				padding: 'calc(var(--spacing-1) * 1.5)',
				borderColor: 'transparent',
				'&:hover, :focus': {
					color: 'var(--button-ghost-secondary-hover-color)',
					backgroundColor: 'var(--button-ghost-secondary-hover-bgcolor)',
				},
			},
			transparent: {
				'--font-weight-axis': '400',
				'--font-variation-settings': `'wght' var(--font-weight-axis)`,
				backgroundColor: 'transparent',
				color: 'var(--color-primary)',
				minHeight: 'unset',
				textTransform: 'none',
				padding: 0,
				'&:hover, :focus': {
					color: 'var(--color-primary)',
					backgroundColor: 'transparent',
				},
			},
			text: {
				borderRadius: 0,
				padding: 0,
				minWidth: 'auto',
				border: 0,
				borderBottom: '1px solid',
				'&:hover, :focus': {
					borderColor: 'transparent',
					color: 'var(--color-grey-90)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey-30)',
				},
			},
			textLink: {
				borderRadius: 0,
				padding: 0,
				minWidth: 'auto',
				border: 0,
				textDecoration: 'underline',
				textTransform: 'none',
				'--font-weight-axis': '700',
				'&:hover, :focus': {
					textDecoration: 'underline',
				},
			},
			textLinkSecondary: {
				color: 'var(--color-secondary)',
			},
			textNoLink: {
				borderBottom: 0,
				fontFamily: 'var(--button-font-family)',
				'--font-weight-axis': 'var(--button-font-weight)',
				'--font-variation-settings': `'wght' var(--font-weight-axis)`,
				fontWeight: 'var(--button-font-weight)',
				fontSize: 'var(--button-primary-size)',
				lineHeight: 'var(--button-primary-size)',
			},
			loading: {
				marginRight: 'var(--spacing-2)',
				color: 'inherit',
			},
			textGrey: {
				color: 'var(--color-grey-90)',
			},
		},
	},
	MuiButtonBase: {
		defaultProps: {
			disableRipple: true,
		},
	},
}

export default Button
