import defaultTheme from '@bluheadless/ui/src/theme/default'
import { alpha } from '@mui/system'
import { defaultFontProp, normalVariation } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root': {
				colorScheme: 'light dark',
				// spacing
				'--spacing': '8px',
				'--spacing-1': 'calc(var(--spacing) * 1)',
				'--spacing-2': 'calc(var(--spacing) * 2)',
				'--spacing-3': 'calc(var(--spacing) * 3)',
				'--spacing-4': 'calc(var(--spacing) * 4)',
				'--spacing-5': 'calc(var(--spacing) * 5)',
				'--spacing-6': 'calc(var(--spacing) * 6)',
				'--spacing-7': 'calc(var(--spacing) * 7)',
				'--spacing-8': 'calc(var(--spacing) * 8)',
				'--spacing-9': 'calc(var(--spacing) * 9)',
				'--spacing-10': 'calc(var(--spacing) * 10)',
				// shape
				'--border-radius': '4px',
				// font-size
				'--font-size-headlinebig': defaultTheme.typography.pxToRem(40),
				'--font-size-headlinemedium': defaultTheme.typography.pxToRem(24),
				'--font-size-headlinesmall': defaultTheme.typography.pxToRem(24),
				'--font-size-subheadline1': defaultTheme.typography.pxToRem(16),
				'--font-size-subheadline2': defaultTheme.typography.pxToRem(14),
				'--font-size-headlineDescription': defaultTheme.typography.pxToRem(12),
				'--font-size-h1': defaultTheme.typography.pxToRem(32),
				'--font-size-h2': defaultTheme.typography.pxToRem(24),
				'--font-size-h3': defaultTheme.typography.pxToRem(24),
				'--font-size-h4': defaultTheme.typography.pxToRem(16),
				'--font-size-h5': defaultTheme.typography.pxToRem(14),
				'--font-size-h6': defaultTheme.typography.pxToRem(16),
				'--font-size-body1': defaultTheme.typography.pxToRem(12),
				'--font-size-body2': defaultTheme.typography.pxToRem(14),
				'--font-size-body3': defaultTheme.typography.pxToRem(12),
				'--font-size-body4': defaultTheme.typography.pxToRem(20),
				'--font-size-button': defaultTheme.typography.pxToRem(14),
				'--font-size-caption': defaultTheme.typography.pxToRem(10),
				'--font-size-subtitle1': defaultTheme.typography.pxToRem(16),
				'--font-size-subtitle2': defaultTheme.typography.pxToRem(12),
				'--letter-spacing-default': 0,
				'--button-letter-spacing': 'calc(var(--font-size-body1) * (10/100))',
				'--letter-spacing-10': 'calc(var(--font-size-body1) * (10/100))',
				// font-family
				'--font-montserrat': 'Montserrat',
				'--font-family-regular': 'var(--font-montserrat)',
				'--font-variation-settings': `'wght' var(--font-weight-axis, 400)`,
				// colors
				// brand
				'--color-brand': '#3d3935',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				// default
				'--color-primary': 'var(--color-black)',
				'--color-secondary': 'var(--color-white)',
				'--color-disabled': '#ACACAC',
				'--color-grey-00': '#F9F9F9',
				'--color-grey-10': '#EDEDED',
				'--color-grey-20': '#E1E1E1',
				'--color-grey-30': '#D9D9D9',
				'--color-grey-50': '#959595',
				'--color-grey-60': '#9F9F9F',
				'--color-grey-90': '#373737',
				'--border-color': 'var(--color-grey-20)',
				// buttons
				'--button-font-family': 'var(--font-family-regular)',
				'--button-font-weight': '600',
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-color': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-white)',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				'--button-secondary-hover-color': 'var(--color-primary)',
				'--button-secondary-hover-bgcolor': 'var(--color-white)',
				'--button-ghost-color': 'var(--color-secondary)',
				'--button-ghost-bgcolor': alpha('#000', 0.2),
				'--button-ghost-hover-color': 'var(--color-primary)',
				'--button-ghost-hover-bgcolor': 'var(--color-white)',
				'--button-ghost-secondary-color': 'var(--color-primary)',
				'--button-ghost-secondary-bgcolor': alpha('#fff', 0.4),
				'--button-ghost-secondary-hover-color': 'var(--color-primary)',
				'--button-ghost-secondary-hover-bgcolor': 'var(--color-white)',
				'--button-disabled-color': '--color-disabled',
				'--button-border-radius': '100px',
				// animations
				'--transition-duration': '0.3s',
				'--transition-timing-function': 'cubic-bezier(0.4, 0, 0.2, 1)',
				'--transition-delay': '0s',
				'--transition': 'var(--transition-duration) var(--transition-timing-function) var(--transition-delay)',
				'--swiper-theme-color': 'var(--color-primary)',
				// maps
				'--maps-cluster-bg-color': 'var(--color-brand)',
				// various
				'--backdrop-filter': 'blur(20px)',
				// accessibility
				'--accessibility-focus-color': '#5E9ED6',
				'--accessibility-focus-style': 'solid',
				'--accessibility-focus-width': '2px',
				'--accessibility-focus-outline':
					'var(--accessibility-focus-color) var(--accessibility-focus-style) var(--accessibility-focus-width)',
				[`@media screen and (max-width: ${defaultTheme.breakpoints.values.md - 1}px)`]: {
					'--font-size-headlinebig': defaultTheme.typography.pxToRem(56),
					'--font-size-headlinemedium': defaultTheme.typography.pxToRem(40),
					'--font-size-subheadline2': defaultTheme.typography.pxToRem(16),
					'--font-size-h1': defaultTheme.typography.pxToRem(32),
					'--font-size-h2': defaultTheme.typography.pxToRem(24),
					'--font-size-h3': defaultTheme.typography.pxToRem(24),
					'--font-size-h4': defaultTheme.typography.pxToRem(20),
					'--font-size-h5': defaultTheme.typography.pxToRem(18),
				},
			},
			':focus, :focus-visible': {
				outline: 'none',
			},
			':focus-visible': {
				outline: 'var(--accessibility-focus-outline) !important',
			},
			html: {
				...defaultFontProp,
				'strong, b': {
					'--font-weight-axis': '700',
				},
			},
			body: {
				...defaultFontProp,
				fontSize: 'var(--font-size-body1)',
				lineHeight: defaultTheme.typography.pxToRem(20),
				color: 'var(--color-primary)',
				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						WebkitBoxShadow: '0 0 0 30px white inset !important',
						WebkitTextFillColor: 'var(--color-primary) !important',
					},
				'.align-center': {
					textAlign: 'center',
					'&-m': {
						textAlign: 'left',
						[`@media screen and (max-width: ${defaultTheme.breakpoints.values.md - 1}px)`]: {
							textAlign: 'center',
						},
					},
					'&-d': {
						textAlign: 'left',
						[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
							textAlign: 'center',
						},
					},
				},
				'.font-light': {
					...normalVariation(300),
				},
				'.font-regular': {
					...normalVariation(400),
				},
				'.font-medium': {
					...normalVariation(500),
				},
				'.font-semibold': {
					...normalVariation(600),
				},
				'.font-bold': {
					...normalVariation(700),
				},
				'.font-black': {
					...normalVariation(900),
				},
			},
		},
	},
}

export default CssBaseline
