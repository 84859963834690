import { styled } from '@mui/system'
import Button from '../../molecules/button'
import StorelocatorLink from '@bluheadless/ui/src/organisms/footer/storelocator-link'

const ButtonIcon = styled(Button)`
	padding: 0;
	color: var(--color-secondary);
	.MuiSvgIcon-root {
		color: inherit;
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	}
	+ span {
		margin-left: var(--spacing-1);
	}
`

const Divider = styled('span')`
	background-color: ${({ theme }) => theme.palette.grey.light};
	height: 26px;
	width: 1px;
`

export const StorelocatorLinkStyled = styled(StorelocatorLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(theme.typography.fontSize)};
	color: currentColor;
	.MuiButton-startIcon {
		margin-right: var(--spacing-1);
	}
`

export { ButtonIcon, Divider }
