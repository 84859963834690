import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import Button from '../../button'
import BHLink from '../../link'

const shouldForwardProp = (prop) => !['open', 'anchor'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
`

const NavLinkStyled = styled(BHLink)`
	padding: var(--spacing-1) 0;
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	:has(.RichContent-Html-Root) {
		.RichContent-Html-Root > * {
			padding: var(--spacing-1) calc(var(--spacing-1) * 1.5);
			border-radius: var(--button-border-radius);
		}
	}
	:not(:has(.RichContent-Html-Root)) {
		border-radius: var(--button-border-radius);
	}
`

const NavItem = styled(Typography)`
	${({ theme }) => theme.typography.subtitle1};
	&.menu-item-active {
		${NavLinkStyled} {
			color: ${({ theme }) => theme.palette.secondary.main};
		}
	}
`

const NavSubMenu = styled('div', { shouldForwardProp })`
	position: fixed;
	top: 0;
	width: 100%;
	background: ${({ theme }) => theme.palette.white.main};
	height: 100%;
	padding: 18px;
	will-change: transition;
	z-index: 2;
	${({ theme }) => theme.scrollbar};
	${({ anchor }) =>
		anchor === 'left'
			? `
					left: -100%;
					transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `
			: `
					right: -100%;
					transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `}
	&.nav-open {
		${({ anchor }) => (anchor === 'left' ? 'left: 0%;' : 'right: 0%;')}
		&.nav-moved {
			${({ anchor }) => (anchor === 'left' ? 'left: 100%;' : 'right: 100%;')}
		}
	}
`

const ParentNavTitle = styled(BHLink)`
	margin: var(--spacing-5) auto var(--spacing-2) 0;
	display: block;
	min-height: 20px;
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};

	&.column-layout {
		~ .SubNavContainer-item-cms_block .submenu-item {
			flex-basis: 100%;

			.MuiContainer-root {
				flex-direction: row;
				.MuiGrid-root {
					flex-basis: 50%;
				}
			}
		}
	}
`

const SubNavLinkStyled = styled(BHLink)`
	padding: var(--spacing-1) 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	--font-weight-axis: 700;
	--font-variation-settings: 'wght' var(--font-weight-axis);
	font-variation-settings: var(--font-variation-settings);
	&.icon > div {
		display: grid;
		align-items: center;
		grid-template-columns: 16px 1fr;
		gap: var(--spacing-1);
	}
`

const SubContainerNavItem = styled('div')``

const SubNavItem = styled(Typography)`
	&.submenu-item-active {
		${SubNavLinkStyled} {
			color: ${({ theme }) => theme.palette.secondary.main};
		}
	}
`

const BackButton = styled(Button)`
	margin: 17px auto 0 0;
	border: 0;
	--font-weight-axis: 400;
	text-transform: none;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	.MuiButton-startIcon {
		margin-right: var(--spacing-1);
		> *:nth-of-type(1) {
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		}
	}
`

const SubNavCmsBlock = styled('div')`
	padding: 16px 24px;

	img {
		display: block;
		width: 100%;
	}
`

export {
	BackButton,
	NavMenu,
	NavItem,
	NavLinkStyled,
	NavSubMenu,
	SubNavItem,
	SubNavLinkStyled,
	SubContainerNavItem,
	SubNavCmsBlock,
	ParentNavTitle,
}
